import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muted: true,
      delayedMute: true,
      selectedCard: "",
    };
    this.toggleMute = this.toggleMute.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        delayedMute: false,
      });
    }, 5000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.delayedMute !== this.state.delayedMute) {
      this.setState({
        muted: this.state.delayedMute,
      });
    }
  }

  toggleMute() {
    this.setState((prevState) => ({
      muted: !prevState.muted,
    }));
  }
  handleMouseEnter = (cardIndex) => {
    if (cardIndex !== this.state.selectedCard) {
      this.setState({ selectedCard: cardIndex });
    }
  };

  handleMouseLeave = () => {
    this.setState({ selectedCard: null });
  };

  render() {
    return (
      <div className="App">
        <div
          style={{
            backgroundColor: "#0C0C0C",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "900px",
          }}
        >
          <div className="container">
            <div className="card">
              <div className="card-body">LOGO</div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#0C0C0C",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container" style={{ textAlign: "center" }}>
            <h1>Movie Ar do Free</h1>
            <video
              autoPlay
              controls
              loop
              muted={this.state.muted}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
            >
              <source src="/Video/Car4K.MP4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#0C0C0C",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container">
            <h1 style={{ textAlign: "center" }}>ประวัติ</h1>
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Card title 1</h5>
                  <p className="card-text">
                    เลอมัง คือ รายการแข่งขันรถยนต์ชื่อดังจากประเทศฝรั่งเศส
                    มีชื่อเต็มๆ ว่า 24 hours of le mans (เลอมังส์ 24 ชั่วโมง)
                    เป็นรายการแข่งรถสุดโหดที่ก่อตั้งขึ้นเมื่อปี ค.ศ. 1923
                    ในเมือง Le Mans โดยจุดประสงค์ของการแข่งขันเลอมัง คือ
                    เพื่อทดสอบสมรรถนะของรถยนต์และความแข็งแกร่งของผู้ขับขี่เพราะว่าในการแข่งขัน
                    24 hours of Le Mans
                  </p>
                </div>
                <img src="/Le-Mans.jpg" className="card-img-top" alt="..." />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f5f5f5",
            minHeight: "200vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="container">
            <div className="card-body">
              <div className="row">
                {[
                  {
                    title: "Porsche Macan S",
                    description: "Another card content here.",
                    buttonText: "Go somewhere",
                  },
                  {
                    title: "Range Rover Velar P400e",
                    description: "Another card content here.",
                    buttonText: "Go somewhere else",
                  },
                  {
                    title: "Audi RSQ3",
                    description: "Another card content here.",
                    buttonText: "Go somewhere else",
                  },
                  {
                    title: "Audi RS3",
                    description: "Another card content here.",
                    buttonText: "Go somewhere else",
                  },
                  {
                    title: "Porsche 718 Boxster",
                    description: "Another card content here.",
                    buttonText: "Go somewhere else",
                  },
                  {
                    title: "Porsche Macan 4 (EV)",
                    description: "Another card content here.",
                    buttonText: "Go somewhere else",
                  },
                  {
                    title: "BMW Z4 M40i",
                    description: "Another card content here.",
                    buttonText: "Go somewhere else",
                  },
                  {
                    title: "COMING SOON",
                    description: "Another card content here.",
                    buttonText: "Go somewhere else",
                  },
                ].map((card, cardIndex) => (
                  <div key={cardIndex} className="col-md-4 mb-3">
                    <div
                      className="card"
                      style={{
                        padding: card.title === "COMING SOON" ? "0" : "10px", // กำหนด padding เป็น 0 เฉพาะสำหรับการ์ด "COMING SOON"
                        transition: "transform 0.1s",
                        transform:
                          cardIndex === this.state.selectedCard
                            ? "scale(1.05)"
                            : "scale(1)",
                        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
                      }}
                      onMouseEnter={() => this.handleMouseEnter(cardIndex)}
                      onMouseLeave={this.handleMouseLeave}
                    >
                      <img
                        src={`/Car/car${cardIndex + 1}.png`}
                        className="card-img-top"
                        alt="..."
                        style={{
                          maxWidth: "600px",
                          maxHeight: "400px",
                          padding: card.title === "COMING SOON" ? "0" : "20px",
                        }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{card.title}</h5>
                        <p className="card-text">{card.description}</p>
                        <a href="#" className="btn btn-primary">
                          {card.buttonText}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <footer
          style={{ backgroundColor: "#333", color: "#fff", padding: "20px 0" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h5>ส่วนที่ 1</h5>
                <p>ข้อความหรือข้อมูลสำหรับส่วนที่ 1 ของ footer ที่นี่</p>
              </div>
              <div className="col-md-4">
                <h5>ส่วนที่ 2</h5>
                <p>ข้อความหรือข้อมูลสำหรับส่วนที่ 2 ของ footer ที่นี่</p>
              </div>
              <div className="col-md-4">
                <h5>ติดต่อสอบถาม</h5>
                <p>ข้อความหรือข้อมูลสำหรับส่วนที่ 3 ของ footer ที่นี่</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
